import React, { useEffect } from 'react';

function Offer() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='cont_horizontal shortNote colorWhite'>
            <div className='fontBig'>Oferta <hr /></div>
            <div className='foontNormal'>
                Oferta dla firm - zakres usług i cennik :<br />
                prowadzenie :
                <p>
                    - prowadzenie ksiąg rachunkowych,<br />
                    - prowadzenie ksiąg przychodów i rozchodów (KPiR),<br />
                    - prowadzenie ryczałtów,<br />
                    - obsługa kadr i płac,<br />
                    - doradztwo księgowe,<br />
                    - doradztwo prawne,<br />
                    - rejestracja firmy osoby fizycznej ,<br />
                    - rejestracja firmy osoby prawnej,<br />
                    - analizy finansowe,<br />
                    - przekształcenie spółek,<br />
                </p>
                Cennik (podane ceny są cenami netto)
                <p>
                    - księgi rachunkowe - od 600 PLN<br />
                    - księgi przychodów i rozchodów (KPiR) Ryczałt - od 200 PLN<br />
                    - kadry 40 zł od pracownika<br />
                    - pozostałe usługi - cena ustalana indywidualnie .<br />
                </p>
                <p>
                    Powyższy cennik nie stanowi oferty. Służy do celów informacyjnych.<br /> W przypadku zainteresowania naszą ofertą prosimy o  kontakt.
                </p>
            </div>
        </div>
    );
}

export default Offer;

