import React from 'react';
import { Routes, Route } from 'react-router-dom'

import Home from './Home.js'
import About from './About.js'
import Offer from './Offer.js';
import Contact from './Contcat.js'
import NotFound from './NotFound.js'

function Page({paddingHeader}) {
    
    return ( 
        <div className='page' style={{paddingTop: `${paddingHeader}px`}}>
                <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/offer" element={<Offer />}/>
        <Route path="*" element={<NotFound />}/>

        {/* <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductPage />} />
        <Route path="/contact" element={<Contact handleAreaValue={props.handleAreaValue} areaValue={props.areaValue} />} />
        <Route path="/adminpanel" element={<AdminPanel />} />
        <Route path="/adminpanel/login" element={<Login />} />
        <Route path="*" element={<NotFound />} /> */}

      </Routes>      
            
        </div>
     );
}

export default Page;