import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'

import './Logo.css'
import image from './data/image.png'

function Logo({ mobile, paddingHeader }) {
    const [largeLogo, setLargeLogo] = useState(false)
    const handleMouseOn = () => {
        if (mobile) {
        } else {
            setLargeLogo(true);
        }
    }
    const handleMouseOff = () => {
        if (mobile) {
        } else {
            setLargeLogo(false);
        }
    }
    return (
        <div className='logo'>
            <div className='background_white' style={{ height: `${paddingHeader}px` }}>
            </div>
            <div className='logoDK'>
                <NavLink to="/"><img className={largeLogo ? 'normal' : 'smaller'} src={image} alt="LOGO" onMouseEnter={handleMouseOn} onMouseLeave={handleMouseOff} /></NavLink>
            </div>
            <div className='content fontBig colorBlue'>
                BIURA RACHUNKOWE <br /> <strong>KSIĄŻEK</strong>
            </div>
        </div>
    );
}

export default Logo;