import React, { useEffect } from 'react';

import './Contact.css'

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className='cont_vertical shortNote'>
                <div className='cont_horizontal colorWhite officeContact'>
                    <div className='fontBig'>KONTAKT <hr /></div>
                    <div className='cont_vertical office'>
                        <div >
                            <div className='city fontBig'>KRAKÓW</div>
                            <p>34-404 Kraków<br />
                                ul. Cegielniana 4A/25<br />
                                tel.: 660 996 091, <br />tel.: 883 022 885
                            </p>
                        </div>
                        <div >
                            <div className='city fontBig'>WADOWICE</div>
                            <p>34-100 Wadowice<br />
                                Podstawie 8<br />
                                tel.: 660 996 091, <br />tel.: 883 022 885
                            </p>
                        </div>
                    </div>

                    <div className='cont_horizontal office'>
                        <div className='city cont_horizontal'>
                            <span className='fontNormal'>Zadzwoń: </span>
                            <span className='fontBig'> 666 392 680</span>
                        </div>
                        <div className='city cont_horizontal'>
                            <span className='fontNormal'>Lub napisz: </span>
                            <span><a className='lin' href="mailto:damianksiazek@damianksiazek.pl">damianksiazek@damianksiazek.pl</a></span>
                            <span><a className='lin' href="mailto:edytawarchal@damianksiazek.pl">edytawarchal@damianksiazek.pl</a></span>                           
                        </div>
                    </div>

                </div>
                <div className='officeContact2 colorWhite fontSmall'>
                    <span>
                        <p className='colorWhiteStrong'>BIURO RACHUNKOWE KSIĄŻEK SPÓŁKA Z OGRANICZONA ODPOWIEDZIALNOŚCIĄ</p>
                        <p>34-100 Wadowice, Podstawie 8, tel.: 660 996 091, KRS: 0000972233</p>
                        <p className='colorWhiteStrong'>BIURO RACHUNKOWE KSIĄŻEK I WSPÓLNICY SP. Z O.O.</p>
                        <p>30-404 Kraków, ul. Cegielniana 4A/25, tel.: 666 392 680, KRS: 0000482198</p>
                        <p className='colorWhiteStrong'>BIURO RACHUNKOWE DK SP Z O.O.</p>
                        <p>34-100 Wadowice, Podstawie 8, tel.: 660 996 091, tel.: 883 022 885, KRS: 0000586027</p>
                    </span>
                </div>
            </div>

            <div className='localization fontBig'>Lokalizacja w KRAKOWIE <hr /></div>
            <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d453.0940340047162!2d19.935348909266647!3d50.02847221070702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165ca3b74ab023%3A0x41ccdeceb39098ca!2sCegielniana%204A%2C%2030-404%20Krak%C3%B3w!5e0!3m2!1spl!2spl!4v1653661985311!5m2!1spl!2spl"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" title='Mapa Kraków'>
            </iframe>

            <div className='localization fontBig'>Lokalizacja w WADOWICACH <hr /></div>
            <iframe className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d642.6264099470552!2d19.49835912924396!3d49.88930969871264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471688db5b49f8ff%3A0x20f4b870421b39f!2sPodstawie%208%2C%2034-100%20Wadowice!5e0!3m2!1spl!2spl!4v1653662762930!5m2!1spl!2spl"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" title='Mapa Kraków'>
            </iframe>
        </>
    );
}

export default Contact;