import React, { useState, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";

import Page from './Page.js'
import Header from './Header.js'
import Footer from './Footer.js';
import './App.css';


function App() {
  const [buttonUp, setButtonUp] = useState(false);
  const [paddingHeader, setPaddingHeader] = useState(0);
  const [mobile, setMobile] = useState(false);

  // const [scrollValue, setScrollValue] = useState(0);

  const clickUp = () => {
    window.scrollTo(0, 0);
  }
  const checkScroll = () => {          
      const conthead = document.querySelector(".head");
      setPaddingHeader(conthead.clientHeight);     
      // console.log(a);
      if (conthead.clientWidth < 900) {
        setMobile(true);
      } else {setMobile(false)}
      // console.log([conthead.clientWidth]);
    }
  


  const handlescroll = () => {
    // checkScroll(true)    
    // setScrollValue(window.scrollY);
    if (window.scrollY > 10) {
      setButtonUp(true);
    } else { setButtonUp(false) }
  }

  useEffect(() => {
    checkScroll();
    // setMobile(isMobile.any())
    console.log("mobile")
    window.addEventListener("scroll", handlescroll);
    window.addEventListener("resize", checkScroll);
    return () => {
      window.removeEventListener("scroll", handlescroll);
      window.removeEventListener("resize", checkScroll);
    }
  },[])

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div className="App">
        <header>
          {<Header
            buttonUp={buttonUp}
            paddingHeader={paddingHeader}
            mobile={mobile}
          />}
        </header>
        <section>
          <Page
            mobile={mobile}
            paddingHeader={paddingHeader}
          />
        </section>
        <footer>
          <Footer/>
        </footer>
      </div>
      {buttonUp ? <div className='buttonUp' onClick={clickUp}>
        <span className="material-symbols-outlined visible">vertical_align_top</span>
      </div> : <div className='buttonUp' onClick={clickUp}>
        <span className="material-symbols-outlined novisible">vertical_align_top</span>
      </div>
      }
    </BrowserRouter>
  );
}

export default App;
