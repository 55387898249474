import React from 'react';

import './Footer.css'
function Footer() {
    
    
    return (
        <div className='mainFooter cont_vertical'>
            <div className='section'>
                <div className='city fontBig'>KRAKÓW</div>
                <p>34-404 Kraków<br />
                    ul. Cegielniana 4A/25<br />
                    tel.: 660 996 091, <br/>tel.: 883 022 885
                </p>
            </div>

            <div className='section'>
                <div className='city fontBig'>WADOWICE</div>
                <p>34-100 Wadowice<br />
                     Podstawie 8 <br />
                     tel.: 660 996 091, <br/>tel.: 883 022 885
                </p>
            </div>
            

            <div className='cont_horizontal con_contact'>
                <div className='sectionContact'>
                    <span className='fontNormal'>Zadzwoń: </span>
                    <span className='fontBig'>{"\u00A0"}660 996 09</span>
                </div>
                <div className='sectionContact'>
                    <span className='fontNormal'>Zadzwoń: </span>
                    <span className='fontBig'>{"\u00A0"}883 022 885</span>
                </div>
                <div className='sectionContact cont_vertical'>
                    <span className='fontNormal'>Napisz:{"\u00A0"}</span>
                    <span className='fontNormal'> <a href="mailto:damianksiazek@damianksiazek.pl">damianksiazek@damianksiazek.pl</a></span>
                    </div>
                    <div className='sectionContact cont_vertical'>
                    <span className='fontNormal'>Napisz:{"\u00A0"}</span>
                    <span className='fontNormal'> <a href="mailto:edytawarchal@damianksiazek.pl.pl">edytawarchal@damianksiazek.pl</a></span>
                </div>
                <div className='sectionContact'>
                    <span className='fontVerySmall'>COPYRIGHT © BIURA RACHUNKOWE KSIĄŻEK | CREATED BY <a href="mailto:kslukasz@o2.pl"><span className='fontSmall'>kslukasz@o2.pl</span></a></span>
                   
                </div>
            </div>

        </div>
    );
}

export default Footer;