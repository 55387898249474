import React from 'react';
import { NavLink } from 'react-router-dom'

import './NotFound.css'

function NotFound() {
    return ( 
        <div className='cont_horizontal  colorWhite shortNote'>
          <div className='fontBig'>Nie znaleziono strony...<hr/></div>
          
          <div className="linkNotFound"><NavLink to="/">
          <span className="material-symbols-outlined">arrow_back</span>
              Powrót do strony głównej</NavLink></div>
            </div>
            
     );
}

export default NotFound;