import React, {useEffect} from 'react';

import './About.css'
function About() {
    useEffect(()=>
    {
        window.scrollTo(0, 0);
    },[]);
    
    return (
        <div className="cont_horizontal shortNote colorWhite">
            <div className='fontBig'>O firmie Książek - usługi rachunkowe, prowadzenie ksiąg rachunkowych<hr /></div>
            <div className='fontNormal indent'>
                Jesteśmy firmą działającą na rynku od 2007 r. Nasz profesjonalizm doceniło już wiele podmiotów gospodarczych. Aktualnie klientami biur jest ponad 700 firm z terenu całego województwa małopolskiego i nie tylko. Nasza firma to zespół pracowników o wysokich kwalifikacjach posiadających 15- letnie doświadczenie, stały dostęp do aktualnych aktów prawnych i orzecznictwa pozwala nam na błyskawiczne informowanie klientów o zmianach dotyczących ich działalności. 
                <p>Oferujemy stałe konsultacje prawno-finansowe ułatwiające podejmowanie każdej decyzji związanej z prowadzoną działalnością.</p> 
                <p>Zajmujemy się obsługą mikro, małych i średnich przedsiębiorstw. Prowadzimy księgi handlowe, księgi przychodów i rozchodów, ewidencje ryczałtu, kadry oraz płace. Naszych klientów reprezentujemy przed Urzędem Skarbowym oraz ZUS-em.</p>
                <p>Zapraszamy do skorzystania z naszych usług księgowych, w tym prowadzenia ksiąg rachunkowych już teraz!</p> 
                
            </div>
        </div>
    );
}

export default About;