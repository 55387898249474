import React from 'react';
import { NavLink } from 'react-router-dom'

import Logo from './Logo.js'
import './Header.css'



function Header({ mobile, paddingHeader }) {

    return (
        <>
            <div className={`cont_vertical head`}>
                <Logo
                    mobile={mobile}
                    paddingHeader={paddingHeader}
                />
                <div className='nav_buttons cont_vertical'>                    
                    <div className='background_grey'> </div>
                    <NavLink to="/about"><div className="link colorBlueStrong">O nas</div></NavLink>
                    <NavLink to="/offer"><div className="link colorBlueStrong">Oferta</div></NavLink>
                    <NavLink to="/contact"><div className="link colorBlueStrong">Kontakt</div></NavLink>
                </div>
            </div>
            <div></div>
        </>
    );
}

export default Header;