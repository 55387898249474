import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom'

import imageDesk from './data/desk.jpg'
import './Home.css'

function Home() {
    useEffect(()=>
    {
        window.scrollTo(0, 0);
    },[]);
    
    return ( 
        <>
        <div className='cont_vertical home'>
            <div className='shortNote colorWhite'>
                <p className='fontBig'>Biura Rachunkowe Książek</p>
                <p>Witamy na oficjalnej stronie</p>
                <hr />
                <p className='fontSmall contentLeft'>Posiadamy szeroki zakres usługi w zakresie obsługi kadr i płac, rachunkowości, księgowości, analiz finansowych oraz wielu innych aspektów dotyczących rozliczeń finansowych. Nasze biura księgowe posiada szeroko grono zadowolonych klientów, bądź jednym z nich!</p>
                <p className='linkRight'><NavLink to="/about">czytaj więcej</NavLink></p>
            </div>
            <div className='cont_horizontal shortNoteRight colorWhite'>
            <NavLink to="/offer"><div className='desk cont_horizontal'><img src={imageDesk} alt="Desk" title="Oferta"/></div></NavLink>
                
            </div>
        </div>
        <br/>
        <div className='cont_horizontal shortNote colorWhite fontSmall'>
            <span>Nasze biura rachunkowe od lat zajmują się kompleksową obsługą małych i dużych przedsiębiorstw w zakresie szeroko rozumianej księgowości i rachunkowości. Zajmujemy się nie tylko bieżącym prowadzeniem dokumentacji – naszym klientom oferujemy również usługi związane z analizą finansową i opracowywaniem polityki rachunkowości.</span>
            <p>Nasza wykwalifikowana kadra jest na bieżąco z wszelkimi zmianami prawnymi dotyczącymi przedsiębiorstw, dzięki czemu każdemu naszemu klientowi gwarantujemy opiekę także na tym polu.</p>
            </div>
        </>
     );
}

export default Home;